import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { ButtonLink } from '../components/ui/button-link';

function SuccessPage(): React.ReactElement {
  return (
    <>
      <SEO title="Success Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/home-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <>
              <h1 className="text-6xl font-semibold">Success!</h1>
              <p>Thank you, our team will get back to you shortly</p>
            </>
          }
          cta={
            <ButtonLink variant="teal" to="/">
              Return Home
            </ButtonLink>
          }
          waveColor="text-transparent"
        />
        <div className="mt-[-4rem] md:mt-[-6.5rem]">
          <ContactSection />
        </div>
      </Layout>
    </>
  );
}

export default SuccessPage;
